<template>
  <div class="sidebar">

    <el-menu
        class="sidebar-el-menu"
        :default-active="onRoutes"
        :collapse="collapse"
        background-color="#324157"
        text-color="#bfcbd9"
        active-text-color="#20a0ff"
        unique-opened
        router>
      <el-menu-item index="/dashboard">
        <i class="el-icon-lx-home"></i>
        <template #title>首页</template>
      </el-menu-item>
      <el-submenu index="2-4">
        <template #title>
          <i class="el-icon-s-tools"></i>
          <span>官网管理</span>
        </template>
        <el-menu-item :index="h.index" v-for="h in home" :key="h.index">
          <i :class="h.icon"></i>
          <template #title>{{ h.title }}</template>
        </el-menu-item>
      </el-submenu>
<!--      <el-menu-item index="/ai">-->
<!--        <i class="el-icon-s-platform"></i>-->
<!--        <template #title>AI</template>-->
<!--      </el-menu-item>-->

      <el-submenu index="2-5">
        <template #title>
          <i class="el-icon-s-platform"></i>
          <span>项目管理</span>
        </template>
        <el-menu-item :index="h.index" v-for="h in projects" :key="h.index">
          <i :class="h.icon"></i>
          <template #title>{{ h.title }}</template>
        </el-menu-item>
      </el-submenu>

    </el-menu>
  </div>
</template>

<script>
export default {
  data() {
    return {
      route: [],
      home: [
        {
          icon: "el-icon-lx-read",
          index: "/cases",
          title: "内容管理",
        },
      ],
      projects: [
        {
          icon: "el-icon-lx-read",
          index: "/item",
          title: "项目管理",
        },
        {
          icon: "el-icon-suitcase",
          index: "/itemStatistics",
          title: "项目统计",
        },
        {
          icon: "el-icon-s-goods",
          index: "/product",
          title: "产品管理",
        },
      ],
    };
  },
  created() {
    this.getroute();

  },
  methods: {
    getroute() {
    }
  },
  computed: {
    onRoutes() {
      return this.$route.path;
      // return this.$route.path.replace("/", "");
    },
    collapse() {
      return this.$store.state.collapse;
    },
  },
};
</script>

<style scoped>
.sidebar {
  display: block;
  position: absolute;
  left: 0;
  top: 0px;
  bottom: 0;
  overflow-y: scroll;
}

.sidebar::-webkit-scrollbar {
  width: 0;
}

.sidebar-el-menu:not(.el-menu--collapse) {
  width: 200px;
}

.subs {
  background-color: #ffffff !important;
}

.sidebar > ul {
  height: 100%;
}
</style>
